var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"WTUBd7tUAyk6b5FiiIpU9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  ignoreErrors: [
    /Loading chunk (\d+) failed/, //Chunkloader error
    /^null is not an object \(evaluating 'c\.tagName'\)/, //Tagname error
    /^Intl\.PluralRules is not a constructor/, //Plural rules error
    /Failed to read the 'localStorage' property from 'Window': Access is denied for this document/, //Local storage access error
    /Invariant: attempted to hard navigate to the same URL.*/ //Attempting to navigate same URL error
  ],
  tracesSampleRate: 1.0,
  environment:process.env.NODE_ENV
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
// adding commit to redeploy
